/**
 * move contact details into title
 */
(() => {
  const title = document.querySelector('.contact-header');
  if (!title) return;

  const infoWrapper = document.querySelector('.contact-info');
  const detailsWrapper = document.querySelector('.contact-details');
  const contactAddress = document.querySelector('.contact-address').cloneNode(true);
  const contactPhone = document.querySelector('.contact-phone').cloneNode(true);
  const contactEmail = document.querySelector('.contact-email').cloneNode(true);
  const openingHours = document.querySelector('.opening-hours').cloneNode(true);

  infoWrapper.prepend(contactAddress);
  detailsWrapper.appendChild(contactEmail);
  detailsWrapper.appendChild(contactPhone);
  detailsWrapper.appendChild(openingHours);
})();
