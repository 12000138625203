/**
 * Sticky Tab Module
 */
(() => {
  const stickyTab = document.querySelector('.service-portal');
  if (!stickyTab) return;

  document.body.prepend(stickyTab);
  stickyTab.addEventListener('click', () => {
    stickyTab.classList.toggle('nActive');
  });

  const updateStickyTab = () => {
    const container = document.querySelector('.container');
    // get position from right of div to right of screen
    const right = window.innerWidth - container.getBoundingClientRect().right;
    stickyTab.style.right = `${right}px`;
  };

  updateStickyTab();
  window.addEventListener('resize', updateStickyTab);
})();
