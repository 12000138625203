/**
 * Hero Background
 */
(() => {
  const heroContent = document.querySelector('.hero');
  if (!heroContent) return;

  const heroSlides = document.querySelectorAll('.hero-bg');

  // If less than 2 slides, end function
  if (heroSlides.length < 2) return;

  heroSlides.forEach((slide) => {
    heroContent.append(slide);
  });

  // Add class for slick
  heroContent.classList.add('js-heroslider');
})();

$(document).ready(() => {
  $('.js-heroslider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  });
});
