/**
 * Projects & News Module
 */

// moving news
(() => {
  const newsContainer = document.querySelector('.js-news-container');
  if (!newsContainer) return;

  const newsPostData = document.querySelector('.js-news-data');
  if (!newsPostData) return;

  newsPostData.querySelectorAll('.tile-blog').forEach((news) => {
    newsContainer.append(news);
  });
})();

// moving projects
(() => {
  const projectsContainer = document.querySelector('.js-projects-container');
  if (!projectsContainer) return;

  const projectsPostData = document.querySelector('.js-projects-data');
  if (!projectsPostData) return;

  projectsPostData.querySelectorAll('.tile-blog').forEach((project) => {
    projectsContainer.append(project);
  });
})();

// projects slick
$(document).ready(() => {
  $('.js-projects-container.js-slick').slick({
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
});

/**
 * Testimonials slick
 */
(() => {
  document.querySelectorAll('.blog-testimonials.js-slick').forEach((item) => {
    // container wrap
    const containerWrapper = document.createElement('div');
    containerWrapper.className = 'container';
    while (item.firstChild) {
      containerWrapper.appendChild(item.firstChild);
    }

    item.appendChild(containerWrapper);
  });
})();

$(document).ready(() => {
  $('.blog-testimonials.js-slick .blog-posts-grid-view').slick({
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
