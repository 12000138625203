/**
 * Read more on Mobile
 */
(() => {
  const readMore = document.querySelector('.services-text');
  if (!readMore) return;

  const button = readMore.querySelector('.readmore');
  button.addEventListener('click', () => {
    readMore.classList.toggle('nActive');
  });
})();
