/**
 * Move Case Study Name to header
 */
(() => {
  const query = document.querySelector('.blog-post-details');
  if (!query) return;

  const heroTitle = document.querySelector('.hero-banner .header h1');
  const caseStudyName = document.querySelector('.blog-post-details .post-title h2');
  const cloneCaseTitle = caseStudyName.cloneNode(true);
  const caseTitleHTML = cloneCaseTitle.innerHTML;
  heroTitle.innerHTML = caseTitleHTML;
})();
