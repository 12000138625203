//   /**
//  * Count up to (variable) for Years Of Experience
//  */
(() => {
  const query = document.querySelector('.years-experience');
  if (!query) return;

  // count up to maximum number. Variable in evolutionX
  const elements = document.querySelectorAll('.counter');

  function countUp(element, target, duration) {
    if (element.classList.contains('isRunning')) return;
    element.classList.add('isRunning');
    const start = 0;
    const step = target / (duration / 100);
    let current = start;

    const interval = setInterval(() => {
      current += step;
      if (current >= target) {
        clearInterval(interval);
        current = target;
      }
      element.textContent = Math.round(current);
    }, 100);
    element.classList.remove('isRunning');
  }

  // IntObs to run function
  const target = document.querySelector('.we-intobs');
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        elements.forEach((element) => {
          const targetNum = parseInt(element.getAttribute('data-num'), 10);
          countUp(element, targetNum, 2000);
        });
      }
    });
  });
  observer.observe(target);
})();
