// import dJSON from 'dirty-json';
// import '../node_modules/@lottiefiles/lottie-player/dist/lottie-player';
import '../node_modules/accessible-slick/slick/slick';

import './js/base';
import './js/global';

import './js/slick-init';

// pages
import './layouts/about-us/layout';
import './layouts/case-studies/layout';
import './layouts/contact-us/layout';

// modules
import './modules/hero/module';
import './modules/category-menu-boxes/module';
import './modules/services-we-offer/module';
import './modules/custom-merchandising/module';
import './modules/who-are-we/module';
import './modules/brands-carousel/module';
import './modules/blog/module';
import './modules/sticky-tab/module';
