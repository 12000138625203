/**
 * Cat boxes - reposition items within
 */
(() => {
  const readMore = document.querySelector('.cat-menu-boxes');
  if (!readMore) return;
  document.querySelectorAll('.cat-menu-boxes').forEach((menu) => {
    menu.querySelectorAll(':scope > ul > li').forEach((item) => {
      const bgImage = item.querySelector('.cat-box-bg a img');
      const tierOneAnchor = menu.querySelector(':scope > ul > li > a');
      const submenu = item.querySelector('.submenu');
      /**
       * Adding See All to bottom of list *
       */
      (() => {
        const newLi = document.createElement('li');
        const cloneTierOne = tierOneAnchor.cloneNode(true);
        newLi.append(cloneTierOne);
        submenu.append(newLi);
        cloneTierOne.innerHTML = 'See All';
      })();

      /**
       *
       */
      (() => {
        const anotherLi = document.createElement('li');
        anotherLi.append(tierOneAnchor);
        submenu.prepend(anotherLi);
      })();

      /**
       * Moving the chair icon to top
       */
      (() => {
        const chairIcon = item.querySelector('.cat-box-icon');
        submenu.prepend(chairIcon);
      })();

      // moving bg image to the top level <li>
      item.prepend(bgImage);
    });
  });
})();

/**
* accordian for mobile and tablet
*/
(() => {
  const fullList = document.querySelector('.hasSubmenus');
  if (!fullList) return;

  fullList.querySelectorAll('.has-submenu').forEach((item) => {
    item.addEventListener('click', () => {
      item.classList.toggle('nActive');
    });
  });
})();
