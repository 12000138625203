/**
 * move contact details into wrapper
 */
(() => {
  const query = document.querySelector('.contact-us');
  if (!query) return;

  // ** move contact details into wrapper
  // ** wrappers
  const emailWrapper = query.querySelector('.sales-team .email-wrapper');
  const phoneWrapper = query.querySelector('.sales-team .phone-wrapper');
  const chatWrapper = query.querySelector('.sales-team .chat-wrapper');

  // ** details
  const email = document.querySelector('.contact-email');
  const phone = document.querySelector('.contact-phone');
  const chat = document.createElement('a');
  chat.href = '#';
  chat.classList.add('contact-chat');
  chat.innerHTML = 'WebChat';

  // ** append
  emailWrapper.append(email);
  phoneWrapper.append(phone);
  chatWrapper.append(chat);

  // ** style class
  const emailClass = email.querySelector('.contact-description a');
  emailClass.classList.add('tile-readmore');
  const phoneClass = phone.querySelector('.contact-description p');
  phoneClass.classList.add('tile-readmore');
})();
