// $(document).ready(() => {
//   $('.js-brands .js-slick').slick({
//     dots: false,
//     infinite: true,
//     speed: 300,
//     autoplay: false,
//     autoplaySpeed: 3000,
//     slidesToShow: 6,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 991,
//         settings: {
//           slidesToShow: 4,
//           slidesToScroll: 4,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//     ],
//   });
// });
