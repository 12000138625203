/** Returns a function, that, as long as it continues to be invoked, will not
 * be triggered.
 *
 * Example usage:
 * function saveInput(){
 *  `console.log('Saving data');
 * }
 * `const processChange = debounce(() => saveInput());
 */
// eslint-disable-next-line import/prefer-default-export
export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

/**
 * Replace .social with .nSocial
 * This is so we can style socials properly without un-styling the default
 */
(() => {
  document.querySelectorAll('.social').forEach((item) => {
    item.classList.remove('social');
    item.classList.add('nSocial');
  });
})();

/**
 * selectors to hide elements.
 * place these on the page-content evo widget where possible
 */
(() => {
  // add breadcrumb-hide class anywhere on page to hide row with breadcrumbs
  document.querySelectorAll('.js-breadcrumb-hide').forEach(() => {
    document.querySelectorAll('#breadcrumb').forEach((item) => {
      item.style.display = 'none';
    });
  });

  // margins for: zonetop, zonebottom, catalog-grid.
  if (document.querySelector('.js-zonetop-nomargin')) {
    document.querySelector('.zonetop').style.marginBottom = '0';
  }
  if (document.querySelector('.js-zonebottom-nomargin')) {
    document.querySelector('.zonebottom').style.marginTop = '0';
  }
  if (document.querySelector('.js-catalog-nomargin')) {
    document.querySelector('.catalog-grid').style.marginTop = '0';
  }

  // no margins for all: zonetop, zonebottom, catalog-grid.
  if (document.querySelector('.js-no-margins')) {
    if (document.querySelector('.zonetop')) {
      document.querySelector('.zonetop').style.marginBottom = '0';
    }

    if (document.querySelector('.zonebottom')) {
      document.querySelector('.zonebottom').style.marginTop = '0';
    }

    if (document.querySelector('.catalog-grid')) {
      document.querySelector('.catalog-grid').style.marginTop = '0';
    }
  }

  // no margins for all, and hide breadcrumb
  if (document.querySelector('.js-no-margins-breadcrumb')) {
    if (document.querySelector('.zonetop')) {
      document.querySelector('.zonetop').style.marginBottom = '0';
    }

    if (document.querySelector('.zonebottom')) {
      document.querySelector('.zonebottom').style.marginTop = '0';
    }

    if (document.querySelector('.catalog-grid')) {
      document.querySelector('.catalog-grid').style.marginTop = '0';
    }

    document.querySelectorAll('#breadcrumb').forEach((item) => {
      item.style.display = 'none';
    });
  }
})();

/**
 * Additional JS Selectors
 */
(() => {
  // add ieOnly class to body if the browser used is IE
  if (navigator.userAgent.match(/msie|trident/i)) {
    document.body.classList.add('ieOnly');
  }

  // add js-target-blank class to parent to allow target blank on 'a' children
  document.querySelectorAll('.js-target-blank a').forEach((item) => {
    item.target = '_blank';
  });

  // add js-customer-tools to element to toggle customer tools slide-in
  document.querySelectorAll('.js-customer-tools').forEach((item) => {
    item.addEventListener('click', () => {
      document.querySelector('.customertools')?.classList.toggle('open');
    });
  });
})();

/**
 * Footer Links Menu Accordion
 * Add class "foot-menu" to menu widget in footer
 */
(() => {
  document.querySelectorAll('.foot-menu').forEach((item) => {
    item.addEventListener('click', () => {
      item.classList.toggle('open');
    });
  });

  /**
 * Change Contact Us to Get In Touch
 */
  (() => {
    const contactUS = document.querySelector('.footer .contacts h4');
    contactUS.innerHTML = 'Get In Touch';

    // * Adding the hours and icon divs into footer
    const contacts = document.querySelector('.footer .contacts');
    const openHours = document.createElement('div');
    openHours.classList.add('contact-block', 'opening-hours');
    contacts.append(openHours);
    const hoursIcon = document.createElement('div');
    hoursIcon.classList.add('fa', 'hours-icon');
    const hoursDesc = document.createElement('div');
    hoursDesc.classList.add('hours-description');
    hoursDesc.innerHTML = 'Mon – Fri 09:00 – 17:00';
    openHours.append(hoursIcon);
    openHours.append(hoursDesc);
  })();
})();
