/**
 * Title Decoration
 */
(() => {
  const isHeadingElement = (element) => {
    const headingTags = ['H1', 'H2', 'H3', 'H4'];
    return headingTags.some((tag) => element.matches(tag));
  };

  document.querySelectorAll('.js-title-decoration').forEach((item) => {
    const isHeading = isHeadingElement(item);

    if (isHeading) {
      item.classList.add('title-decoration');
      item.insertAdjacentHTML('afterbegin', '<span></span>');
      item.insertAdjacentHTML('beforeend', '<span></span>');
    } else {
      item.querySelectorAll(':scope > h1, :scope > h2, :scope > h3, :scope > h4').forEach((heading) => {
        heading.classList.add('title-decoration');
        heading.insertAdjacentHTML('afterbegin', '<span></span>');
        heading.insertAdjacentHTML('beforeend', '<span></span>');
      });
    }
  });
})();
