(() => {
  document.querySelectorAll('.js-brands').forEach((item) => {
    // container wrap
    const containerWrapper = document.createElement('div');
    containerWrapper.className = 'container';
    while (item.firstChild) {
      containerWrapper.appendChild(item.firstChild);
    }

    item.appendChild(containerWrapper);

    const slickWrapper = document.createElement('div');
    slickWrapper.className = 'js-slick';
    item.querySelectorAll('.tileparent').forEach((tileparent) => {
      tileparent.querySelector('.imgthumbnail').className = '';
      slickWrapper.append(tileparent.querySelector('a'));
    });
    item.querySelector('.container').append(slickWrapper);
    item.querySelector('.griditems').style.display = 'none';
  });
})();

$(document).ready(() => {
  $('.js-brands .js-slick').slick({
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });
});
